/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState, FC } from 'react';
import { ListProps, Record, useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { luxon } from 'services/luxon';
import DateSelect from 'components/DateSelect';
import CustomSelect, { ICustomItem } from 'components/CustomSelect';
import RepairDialog from '../components/repair-modal';
import { useStyles } from './job-repairs-sheet-styles';

export type ImageType = 'Photo' | 'Screenshot';

export type Image = {
  id: string;
  fileUrl: string;
  fileName: string;
  repairPhotoType: ImageType;
};

export type RepairPayment = {
  id: string;
  repairFormulaId: string;
  approvedHours?: number | null;
  estimatedHours?: number | null;
  fixedAmount?: number | null;
  extraHours?: number | null;
  contractorId: string;
  contractor?: { name: string };
  repairResourceType: string;
  regionTimeWindowId?: string;
  windowStartTime?: string | null;
  windowEndTime?: string | null;
};

export interface Repair extends Record {
  createdAt: string;
  updatedAt: string;
  subdivisionName: string;
  builderName: string;
  buildingNumber: string;
  jobLot: string;
  jobId: string;
  address1: string;
  regionId: string;
  repairDate: string;
  complete: boolean;
  description: string;
  repairType: string;
  poTotal: string;
  jobRepairPhotos: Image[];
  repairPayments: RepairPayment[];
}

const from = new Date();
const to = luxon.addDays(luxon.date(from), 90).toBSON();

const types: ICustomItem[] = [{ id: 'repair', name: 'Repair' }];

export const RepairList: FC<ListProps> = props => {
  const [date, setDate] = useState(new Date());
  const [repairType, setRepairType] = useState(types[0].id);
  const [loading, setLoading] = useState(false);
  const [repairs, setRepairs] = useState<Repair[]>([]);
  const [selectedRepair, setSelectedRepair] = useState<Repair>({} as Repair);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { location } = props;
  const searchParams = new URLSearchParams(location?.search);

  const classes = useStyles();

  const onRepairClick = (repair: Repair) => {
    setSelectedRepair(repair);
    setShowRepairModal(true);
  };

  const getSchedules = useCallback(async () => {
    setLoading(true);
    try {
      const formattedDate = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0'),
      ].join('-');
      const { data } = await dataProvider.getList<Repair>('job-repair-sheet', {
        filter: {
          'repairDate||$gte': formattedDate,
          'repairDate||$lte': formattedDate,
        },
        pagination: { page: 1, perPage: 999 },
        sort: { field: 'id', order: 'DESC' },
      });

      if (data) setRepairs(data);
    } catch (error) {
      notify('Failed to load repairs', 'error');
    }
    setLoading(false);
  }, [date]);

  const renderRepair = (repair: Repair) => {
    const {
      id,
      repairDate,
      repairPayments,
      buildingNumber: rawBuildingNumber,
      jobLot,
      address1,
      builderName,
      subdivisionName,
    } = repair;

    const date = repairDate
      ? luxon.date(new Date(repairDate)).toFormat('MM/dd/yyyy')
      : '';

    const additionalContractors = repairPayments
      ? repairPayments.length - 1
      : 0;
    const firstContractorName = repairPayments?.[0]?.contractor?.name || '-';

    const contractorName = `${firstContractorName}${
      additionalContractors > 0 ? ` +${additionalContractors}` : ''
    }`;

    const buildingNumber = rawBuildingNumber?.padStart(3, '0') || '-';

    const lotNumber = jobLot?.padStart(4, '0') || '-';

    return (
      <Box
        key={id}
        display="flex"
        flexDirection="row"
        className={`${classes.whiteBox} ${classes.clickableBox}`}
        boxShadow={3}
        onClick={() => onRepairClick(repair)}
      >
        <Box
          display="flex"
          flexDirection="column"
          flex={0.5}
          className={classes.info}
        >
          <Box>
            <Typography className={classes.label}>Subdivision</Typography>
            <Typography>{subdivisionName}</Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex={1}>
              <Typography className={classes.label}>Building #</Typography>
              <Typography>{buildingNumber}</Typography>
            </Box>
            <Box flex={1}>
              <Typography className={classes.label}>Lot</Typography>
              <Typography>{lotNumber}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography className={classes.label}>Address</Typography>
            <Typography>{address1}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flex={0.4}
          className={classes.info}
        >
          <Box>
            <Typography className={classes.label}>Repair Date</Typography>
            <Typography>{date}</Typography>
          </Box>
          <Box>
            <Typography className={classes.label}>Builder</Typography>
            <Typography>{builderName}</Typography>
          </Box>
          <Box>
            <Typography className={classes.label}>Contractor</Typography>
            <Typography>{contractorName}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flex={0.1}
          alignItems="center"
          justifyContent="center"
        >
          <AddIcon />
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    getSchedules();
  }, [getSchedules]);

  useEffect(() => {
    const loadFromId = async () => {
      try {
        const id = searchParams.get('id');
        if (id === null) return;
        const { data: repairs } = await dataProvider.getList<Repair>(
          'job-repair-sheet',
          {
            filter: { id },
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'id', order: 'DESC' },
          },
        );
        if (repairs.length === 0) return;
        setSelectedRepair(repairs[0]);
        setShowRepairModal(true);
      } catch (error) {
        notify('Failed to load repair', 'error');
      }
    };

    loadFromId();
  }, [location?.search]);

  const isDataEmpty = repairs.length === 0;

  const pendingRepairs = repairs.filter(repair => !repair.complete);
  const hasPendingRepairs = pendingRepairs.length > 0;

  const completeRepairs = repairs.filter(repair => repair.complete);
  const hasCompleteRepairs = completeRepairs.length > 0;

  return (
    <Box className={classes.container}>
      <Box width="100%">
        <Box margin="0 8px">
          <DateSelect to={to} from={from} selected={date} onSelect={setDate} />
          <Box my="16px">
            <CustomSelect
              disabled
              options={types}
              value={repairType}
              onChange={event => {
                if (event.target?.value)
                  setRepairType(String(event.target?.value));
              }}
            />
          </Box>
        </Box>
      </Box>
      {loading && (
        <Box display="flex" alignItems="center" justifyContent="center" py={12}>
          <CircularProgress size={24} />
        </Box>
      )}
      {!loading && isDataEmpty && (
        <Box display="flex" alignItems="center" justifyContent="center" py={12}>
          <Typography>No results found</Typography>
        </Box>
      )}
      {!loading && !isDataEmpty && (
        <Box>
          {hasPendingRepairs && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginY={2}
              >
                <Typography className={classes.pendingLabel}>
                  Pending
                </Typography>
              </Box>
              {pendingRepairs.map(renderRepair)}
            </>
          )}
          {hasCompleteRepairs && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginY={2}
              >
                <Typography className={classes.completeLabel}>
                  Complete
                </Typography>
              </Box>
              {completeRepairs.map(renderRepair)}
            </>
          )}
        </Box>
      )}
      {showRepairModal && (
        <RepairDialog
          open
          repair={selectedRepair}
          handleClose={() => setShowRepairModal(false)}
          handleConfirm={getSchedules}
        />
      )}
    </Box>
  );
};

export default RepairList;
