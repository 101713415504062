import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { useNotify } from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { axiosInstance } from 'services/http';
import {
  ContractorDto,
  PhaseTypeEnum,
  JobPhasePhotoDto,
  PhaseDto,
  UserDto,
} from '@vatos-pas/common';
import { useStyles } from './master-sheet-styles';
import { Job } from './master-sheet-edit';
import { dataProvider } from 'providers/dataProvider';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ContractorChangeDialog from './master-sheet-contractor-change';
import ContractorHistoryDialog from './master-sheet-contractor-history';
import ConfirmRequestDialog from './master-sheet-confirm-request';
import ConfirmDialog from '../../../components/ConfirmDialog';
import {
  CAN_OVERRIDE_CONTRACTOR,
  CAN_REQUEST_RESOURCE,
  CAN_REQUEST_RESOURCE_ASSIGNED,
  CAN_TOGGLE_GARAGE,
  CAN_APPROVE_PHASE,
} from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';

interface MasterSheetTimelineProps {
  theJob: any;
  geoLocationError: string;
  getPhase: any;
  isInWarranty: any;
  setOpenConfirmGoBack: any;
  refreshJobPhases: any;
  setToggleGarage: any;
  toggleGarage: boolean;
  setIsViewPhotos: any;
  setUploadModalOpen: any;
  setSelectedPhase: any;
  showBump: any;
}

const getResourceRequested = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const phaseJob = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    phaseType,
  );
  if (phaseJob) {
    if (phaseJob.dateRequested) {
      const dateRequested = new Date(phaseJob.dateRequested);
      return `${
        dateRequested.getMonth() + 1
      }/${dateRequested.getDate()}/${dateRequested.getFullYear()}`;
    }
  }
};

const getResourceAllocated = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
  currentContractor = false,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  const contractorFound = theJob.contractors.find(
    (contractor: ContractorDto) =>
      contractor.id ===
      // If it's bumpout phase, we don't have contractor history
      // Instead we render the current contractor
      (currentContractor && theJob.subdivision.reqBumpoutTimesheet
        ? jobPhase.contractorId
        : jobPhase.initialContractorId),
  );
  return contractorFound ? contractorFound.name : '';
};

const getSupervisorApproved = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  const supervisorFound = theJob.supervisors.find(
    (user: UserDto) => user.id === jobPhase.approvingUserId,
  );
  return supervisorFound
    ? `${supervisorFound.firstName} ${supervisorFound.lastName}`
    : '';
};

const getStartDate = (phaseType: PhaseTypeEnum, theJob: Job, getPhase: any) => {
  const jobPhase: any = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );

  if (
    (jobPhase.priority === 500 || jobPhase.priority === 400) &&
    jobPhase.approved &&
    jobPhase.customDateStart
  ) {
    const customDateStart = new Date(jobPhase.customDateStart);
    return `${
      customDateStart.getMonth() + 1
    }/${customDateStart.getDate()}/${customDateStart.getFullYear()}`;
  }

  if (jobPhase.dateStart) {
    const dateStart = new Date(jobPhase.dateStart);
    return `${
      dateStart.getMonth() + 1
    }/${dateStart.getDate()}/${dateStart.getFullYear()}`;
  }

  return '';
};

const getApprovedDate = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (jobPhase.dateApproved) {
    const dateStart = new Date(jobPhase.dateApproved);
    return `${
      dateStart.getMonth() + 1
    }/${dateStart.getDate()}/${dateStart.getFullYear()}`;
  }
  return '';
};

const showCameraPhase = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (jobPhase) {
    return !jobPhase.approved && jobPhase.contractorId && jobPhase.requested;
  }
  return false;
};

const isApproved = (phaseType: PhaseTypeEnum, theJob: Job, getPhase: any) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (jobPhase) {
    return jobPhase.approved;
  }
  return false;
};

const showPhaseImages = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  const jobPhaseImages = theJob.jobPhasePhotos.filter(
    (jobPhasePhoto: JobPhasePhotoDto) =>
      jobPhasePhoto.jobPhaseId === jobPhase.id,
  );
  if (jobPhaseImages.length) {
    return true;
  } else {
    return false;
  }
};

const isGarageDisabled = (theJob, getPhase) => {
  const jobPhase = theJob.job.jobPhase;
  if (theJob.job.jobPhase) {
    const garagePhase = getPhase(
      theJob.job,
      theJob.phases,
      theJob.jobPhases,
      PhaseTypeEnum['Garage'],
    );
    if (
      jobPhase.priority < 200 ||
      jobPhase.priority > 300 ||
      jobPhase.requested === true ||
      garagePhase.dateApproved
    ) {
      return true;
    }
  }
  if (theJob.job.pendingReview && jobPhase.priority === 100) {
    return true;
  }
  return false;
};

const onChangeGarage = (
  theJob,
  toggleGarage,
  refreshJobPhases,
  setToggleGarage,
  getPhase,
) => {
  const garagePhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    PhaseTypeEnum.Garage,
  );
  if (garagePhase) {
    const params = {
      id: garagePhase.id,
      data: {
        required: !toggleGarage,
      },
      previousData: garagePhase,
    };
    dataProvider.update('job-phase', params).then(_response => {
      refreshJobPhases();
      setToggleGarage(!toggleGarage);
    });
  }
};

const requestResourceOnPhase = async (
  theJob,
  notify,
  refreshJobPhases,
  suggestedContractorId,
  isMonday,
  sprayHour,
  sprayReadiness,
) => {
  const jobPhase = theJob.jobPhase;
  if (jobPhase) {
    const params: any = {
      id: jobPhase.id,
      data: {
        requested: true,
        requestDelayDays: isMonday ? 2 : 0,
      },
      previousData: jobPhase,
    };
    if (suggestedContractorId) {
      params.data.suggestedContractorId = suggestedContractorId;
    }
    if (sprayReadiness) {
      params.data.sprayReadinessStatus = sprayReadiness;
    }
    if (sprayHour) {
      params.data.readyHour = sprayHour;
    }
    await dataProvider
      .update('job-phase', params)
      .then(() => {
        notify('Resource Requested!');
        refreshJobPhases();
      })
      .catch(error => {
        notify(`Request Resource Error: ${error.message}`, 'warning');
      });
  }
};

const logBumpout = async (
  theJob,
  notify,
  refreshJobPhases,
  suggestedContractorId,
  startDate,
) => {
  const jobPhase = theJob.jobPhase;
  const isBumpoutTimesheetFlow = theJob.subdivision.reqBumpoutTimesheet;

  if (jobPhase) {
    const startDateFormatted = startDate.toISO
      ? startDate.toISO()
      : startDate.toISOString();
    const params: any = {
      jobPhaseId: jobPhase.id,
      date: startDateFormatted,
      contractorId: suggestedContractorId,
    };
    return axiosInstance
      .post('job-phase-bump', params)
      .then(response => {
        console.log(response);
        notify(isBumpoutTimesheetFlow ? 'Bump requested!' : 'Bump Logged!');
        refreshJobPhases();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notify(`Bump Log Error: ${error.response.data.message}`, 'warning');
        } else {
          notify(`Bump Log Error: ${error.message}`, 'warning');
        }
      });
  }
};

const getNextStatusText = theJob => {
  if (theJob.job.pendingReview && theJob.job.jobPhase?.priority === 100) {
    return 'Materials – pending approval';
  }
  const currentPhase = theJob.phases.find(
    (phase: PhaseDto) => phase.id === theJob.jobPhase.phaseId,
  );
  if (currentPhase?.phaseType === PhaseTypeEnum['Materials']) {
    return `Materials Sign Off`;
  }
  if (theJob.jobPhase.requested === true) {
    if (theJob.jobPhase.contractorId) {
      return `${currentPhase?.name}: Resources Allocated`;
    }
    return `${currentPhase?.name}: Resources Requested`;
  } else {
    return `${currentPhase?.name}: Request Resource`;
  }
};

export const MasterSheetTimeline: React.FC<MasterSheetTimelineProps> = ({
  theJob,
  geoLocationError,
  getPhase,
  isInWarranty,
  setOpenConfirmGoBack,
  refreshJobPhases,
  toggleGarage,
  setToggleGarage,
  setIsViewPhotos,
  setUploadModalOpen,
  setSelectedPhase,
  showBump,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const [openContractorModal, setOpenContractorModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openConfirmRequest, setOpenConfirmRequest] = useState(false);
  const [openInitialDate, setOpenInitialDate] = useState(false);
  const [openInitialFinishingDate, setOpenInitialFinishingDate] =
    useState(false);
  const [changedBySupervisor, setChangedBySupervisor] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [changedBySupervisorFinishing, setChangedBySupervisorFinishing] =
    useState(false);
  const [initialFinishingDate, setInitialFinishingDate] = useState('');
  const [selectedJobPhase, setSelectedJobPhasel] = useState<any>();

  const { hasPermission } = PermissionsProvider.useContainer();

  const selectJobForUpdate = (phaseType: any) => {
    const jobPhaseName = theJob.phases.find(
      phase => phase.phaseType === phaseType,
    );
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseId === jobPhaseName.id,
    );
    setSelectedJobPhasel(jobPhaseFound);
    setOpenContractorModal(true);
  };

  const openJobPhaseHistoryModal = (phaseType: any) => {
    const jobPhaseName = theJob.phases.find(
      phase => phase.phaseType === phaseType,
    );
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseId === jobPhaseName.id,
    );
    setSelectedJobPhasel(jobPhaseFound);
    setOpenHistoryModal(true);
  };

  const needStrike = (phaseType: any) => {
    const jobPhaseName = theJob.phases.find(
      phase => phase.phaseType === phaseType,
    );
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseId === jobPhaseName.id,
    );
    return jobPhaseFound.jobPhaseContractorHistories.length > 1;
  };

  const onConfirm = (
    suggestedContractorId: any,
    isMonday: boolean,
    sprayHour,
    isBump: boolean,
    startDate: any,
    sprayReadiness: string,
  ) => {
    if (isBump) {
      logBumpout(
        theJob,
        notify,
        refreshJobPhases,
        suggestedContractorId,
        startDate,
      );
    } else {
      requestResourceOnPhase(
        theJob,
        notify,
        refreshJobPhases,
        suggestedContractorId,
        isMonday,
        sprayHour,
        sprayReadiness,
      );
    }
    setOpenConfirmRequest(false);
  };

  const checkRequestResourcePermission = () => {
    if (theJob.jobPhase.contractorId && !theJob.jobPhase.approved) {
      return hasPermission(CAN_REQUEST_RESOURCE_ASSIGNED);
    }
    return hasPermission(CAN_REQUEST_RESOURCE);
  };

  const isJobPhaseApproved = theJob.jobPhase.approved;
  const hasContractor = !!theJob.jobPhase.contractorId;
  const isRequested = theJob.jobPhase.requested;
  const isBumpoutTimesheetFlow = theJob.subdivision.reqBumpoutTimesheet;

  const getStrikeStyle = (phaseType: PhaseTypeEnum) => {
    if (isBumpoutTimesheetFlow) return undefined;

    return needStrike(phaseType) ? classes.striked : classes.normal;
  };

  const isDisabled =
    isJobPhaseApproved ||
    (!hasContractor && !isRequested) ||
    theJob.jobPhase.priority < 200;

  useEffect(() => {
    const sprayPhase: any = theJob.jobPhases.find(
      jobPhase => jobPhase.priority === 500,
    );
    const finishingPhase = theJob.jobPhases.find(
      jobPhase => jobPhase.priority === 400,
    );
    if (sprayPhase) {
      setInitialDate(sprayPhase.initialDateStart);
      setChangedBySupervisor(!!sprayPhase.customDateStart);
    }
    if (finishingPhase) {
      setInitialFinishingDate(finishingPhase.initialDateStart);
      setChangedBySupervisorFinishing(!!finishingPhase.customDateStart);
    }
  }, [theJob]);

  const bumpoutLabel = isBumpoutTimesheetFlow
    ? 'Request BumpOut'
    : 'Log BumpOut';
  const isBump1Approved = isApproved(PhaseTypeEnum['Bump1'], theJob, getPhase);
  const isBump2Approved = isApproved(PhaseTypeEnum['Bump2'], theJob, getPhase);
  const isBump3Approved = isApproved(PhaseTypeEnum['Bump3'], theJob, getPhase);
  const isBump4Approved = isApproved(PhaseTypeEnum['Bump4'], theJob, getPhase);
  const isBump5Approved = isApproved(PhaseTypeEnum['Bump5'], theJob, getPhase);

  return (
    <Box px={3}>
      <Box className={classes.titleTabTimeline}>
        <Typography className={classes.titleFontBigger}>Timeline</Typography>
        <Box display="flex" alignItems="center">
          {geoLocationError && (
            <Typography className={classes.geoLocationError}>
              {geoLocationError}
            </Typography>
          )}
          <Box
            display="flex"
            alignItems="center"
            className={classes.backgroundButton}
            mb={1}
          >
            <Typography className={classes.titleFont}>
              {getNextStatusText(theJob)}
            </Typography>
            {checkRequestResourcePermission() ? (
              <Fragment>
                <Button
                  variant="contained"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => setOpenConfirmGoBack(true)}
                  disabled={isDisabled}
                >
                  Go Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => setOpenConfirmRequest(true)}
                  disabled={
                    theJob.jobPhase.priority < 200 ||
                    theJob.jobPhase.requested === true
                  }
                >
                  {theJob.jobPhase.priority > 500
                    ? bumpoutLabel
                    : 'Request Resource'}
                </Button>
              </Fragment>
            ) : (
              <Fragment />
            )}
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          width="100%"
          mt={2}
          pb={2}
          className={classes.borderGrid}
        >
          <Box
            display="flex"
            width="15%"
            minWidth="15%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Phase</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Resource Requested</Typography>
          </Box>
          <Box
            display="flex"
            width="18%"
            minWidth="18%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography>Name</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography>Start Date</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography>Approved Date</Typography>
          </Box>
          <Box
            display="flex"
            width="10%"
            minWidth="10%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Typography>Approved By</Typography>
          </Box>
          <Box
            display="flex"
            width="8%"
            minWidth="8%"
            alignItems="center"
            justifyContent="flex-start"
          ></Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          mt={2}
          pb={2}
          className={classes.borderGrid}
        >
          <Box
            display="flex"
            width="15%"
            minWidth="15%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Garage</Typography>
            {hasPermission(CAN_TOGGLE_GARAGE) && (
              <Switch
                checked={toggleGarage}
                onChange={() =>
                  onChangeGarage(
                    theJob,
                    toggleGarage,
                    refreshJobPhases,
                    setToggleGarage,
                    getPhase,
                  )
                }
                color="primary"
                disabled={isGarageDisabled(theJob, getPhase)}
                size="small"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getResourceRequested(PhaseTypeEnum['Garage'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="18%"
            minWidth="18%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              onClick={() => openJobPhaseHistoryModal(PhaseTypeEnum['Garage'])}
              className={
                needStrike(PhaseTypeEnum['Garage'])
                  ? classes.striked
                  : classes.normal
              }
            >
              {getResourceAllocated(PhaseTypeEnum['Garage'], theJob, getPhase)}
            </Typography>
            {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
              isApproved(PhaseTypeEnum['Garage'], theJob, getPhase) && (
                <EditIcon
                  onClick={() => selectJobForUpdate(PhaseTypeEnum['Garage'])}
                  className={classes.editIcon}
                />
              )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getStartDate(PhaseTypeEnum['Garage'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getApprovedDate(PhaseTypeEnum['Garage'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="10%"
            minWidth="10%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getSupervisorApproved(PhaseTypeEnum['Garage'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="8%"
            minWidth="8%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {showCameraPhase(PhaseTypeEnum['Garage'], theJob, getPhase) &&
              hasPermission(CAN_APPROVE_PHASE) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => {
                    setIsViewPhotos(false);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Garage,
                      ),
                    );
                  }}
                >
                  Approve
                </Button>
              )}
            {!showCameraPhase(PhaseTypeEnum['Garage'], theJob, getPhase) &&
              showPhaseImages(PhaseTypeEnum['Garage'], theJob, getPhase) && (
                <CameraAltIcon
                  className={classes.pointer}
                  onClick={() => {
                    setIsViewPhotos(true);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Garage,
                      ),
                    );
                  }}
                />
              )}
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          mt={2}
          pb={2}
          className={classes.borderGrid}
        >
          <Box
            display="flex"
            width="15%"
            minWidth="15%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Hanging</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getResourceRequested(PhaseTypeEnum['Hanging'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="18%"
            minWidth="18%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              onClick={() => openJobPhaseHistoryModal(PhaseTypeEnum['Hanging'])}
              className={
                needStrike(PhaseTypeEnum['Hanging'])
                  ? classes.striked
                  : classes.normal
              }
            >
              {getResourceAllocated(PhaseTypeEnum['Hanging'], theJob, getPhase)}
            </Typography>
            {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
              isApproved(PhaseTypeEnum['Hanging'], theJob, getPhase) && (
                <EditIcon
                  onClick={() => selectJobForUpdate(PhaseTypeEnum['Hanging'])}
                  className={classes.editIcon}
                />
              )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getStartDate(PhaseTypeEnum['Hanging'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getApprovedDate(PhaseTypeEnum['Hanging'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="10%"
            minWidth="10%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getSupervisorApproved(
                PhaseTypeEnum['Hanging'],
                theJob,
                getPhase,
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="8%"
            minWidth="8%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {showCameraPhase(PhaseTypeEnum['Hanging'], theJob, getPhase) &&
              hasPermission(CAN_APPROVE_PHASE) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => {
                    setIsViewPhotos(false);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Hanging,
                      ),
                    );
                  }}
                >
                  Approve
                </Button>
              )}
            {!showCameraPhase(PhaseTypeEnum['Hanging'], theJob, getPhase) &&
              showPhaseImages(PhaseTypeEnum['Hanging'], theJob, getPhase) && (
                <CameraAltIcon
                  className={classes.pointer}
                  onClick={() => {
                    setIsViewPhotos(true);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Hanging,
                      ),
                    );
                  }}
                />
              )}
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          mt={2}
          pb={2}
          className={classes.borderGrid}
        >
          <Box
            display="flex"
            width="15%"
            minWidth="15%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Finishing</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getResourceRequested(
                PhaseTypeEnum['Finishing'],
                theJob,
                getPhase,
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="18%"
            minWidth="18%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              onClick={() =>
                openJobPhaseHistoryModal(PhaseTypeEnum['Finishing'])
              }
              className={
                needStrike(PhaseTypeEnum['Finishing'])
                  ? classes.striked
                  : classes.normal
              }
            >
              {getResourceAllocated(
                PhaseTypeEnum['Finishing'],
                theJob,
                getPhase,
              )}
            </Typography>
            {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
              isApproved(PhaseTypeEnum['Finishing'], theJob, getPhase) &&
              getResourceAllocated(PhaseTypeEnum['Finishing'], theJob, getPhase)
                .length > 0 && (
                <EditIcon
                  onClick={() => selectJobForUpdate(PhaseTypeEnum['Finishing'])}
                  className={classes.editIcon}
                />
              )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getStartDate(PhaseTypeEnum['Finishing'], theJob, getPhase)}
            </Typography>
            {initialFinishingDate && (
              <InfoIcon
                className={classes.info}
                onClick={() => setOpenInitialFinishingDate(true)}
              />
            )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getApprovedDate(PhaseTypeEnum['Finishing'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="10%"
            minWidth="10%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getSupervisorApproved(
                PhaseTypeEnum['Finishing'],
                theJob,
                getPhase,
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="8%"
            minWidth="8%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {showCameraPhase(PhaseTypeEnum['Finishing'], theJob, getPhase) &&
              hasPermission(CAN_APPROVE_PHASE) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => {
                    setIsViewPhotos(false);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Finishing,
                      ),
                    );
                  }}
                >
                  Approve
                </Button>
              )}
            {!showCameraPhase(PhaseTypeEnum['Finishing'], theJob, getPhase) &&
              showPhaseImages(PhaseTypeEnum['Finishing'], theJob, getPhase) && (
                <CameraAltIcon
                  className={classes.pointer}
                  onClick={() => {
                    setIsViewPhotos(true);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Finishing,
                      ),
                    );
                  }}
                />
              )}
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          mt={2}
          pb={2}
          className={classes.borderGrid}
        >
          <Box
            display="flex"
            width="15%"
            minWidth="15%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>Spraying</Typography>
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getResourceRequested(
                PhaseTypeEnum['Spraying'],
                theJob,
                getPhase,
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="18%"
            minWidth="18%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography
              onClick={() =>
                openJobPhaseHistoryModal(PhaseTypeEnum['Spraying'])
              }
              className={
                needStrike(PhaseTypeEnum['Spraying'])
                  ? classes.striked
                  : classes.normal
              }
            >
              {getResourceAllocated(
                PhaseTypeEnum['Spraying'],
                theJob,
                getPhase,
              )}
            </Typography>
            {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
              isApproved(PhaseTypeEnum['Spraying'], theJob, getPhase) &&
              getResourceAllocated(PhaseTypeEnum['Spraying'], theJob, getPhase)
                .length > 0 && (
                <EditIcon
                  onClick={() => selectJobForUpdate(PhaseTypeEnum['Spraying'])}
                  className={classes.editIcon}
                />
              )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getStartDate(PhaseTypeEnum['Spraying'], theJob, getPhase)}
            </Typography>
            {initialDate && (
              <InfoIcon
                className={classes.info}
                onClick={() => setOpenInitialDate(true)}
              />
            )}
          </Box>
          <Box
            display="flex"
            width="16%"
            minWidth="16%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getApprovedDate(PhaseTypeEnum['Spraying'], theJob, getPhase)}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="10%"
            minWidth="10%"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography>
              {getSupervisorApproved(
                PhaseTypeEnum['Spraying'],
                theJob,
                getPhase,
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="8%"
            minWidth="8%"
            alignItems="center"
            justifyContent="flex-start"
          >
            {showCameraPhase(PhaseTypeEnum['Spraying'], theJob, getPhase) &&
              hasPermission(CAN_APPROVE_PHASE) && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classes.buttonTimeline}
                  onClick={() => {
                    setIsViewPhotos(false);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Spraying,
                      ),
                    );
                  }}
                >
                  Approve
                </Button>
              )}
            {!showCameraPhase(PhaseTypeEnum['Spraying'], theJob, getPhase) &&
              showPhaseImages(PhaseTypeEnum['Spraying'], theJob, getPhase) && (
                <CameraAltIcon
                  className={classes.pointer}
                  onClick={() => {
                    setIsViewPhotos(true);
                    setUploadModalOpen(true);
                    setSelectedPhase(
                      getPhase(
                        theJob.job,
                        theJob.phases,
                        theJob.jobPhases,
                        PhaseTypeEnum.Spraying,
                      ),
                    );
                  }}
                />
              )}
          </Box>
        </Box>
        {isInWarranty() && (
          <Fragment>
            <Box
              display="flex"
              width="100%"
              mt={2}
              pb={2}
              className={classes.borderGrid}
            >
              <Box
                display="flex"
                width="15%"
                minWidth="15%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>BumpOut 1</Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getResourceRequested(
                    PhaseTypeEnum['Bump1'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="18%"
                minWidth="18%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  onClick={
                    isBumpoutTimesheetFlow
                      ? undefined
                      : () => openJobPhaseHistoryModal(PhaseTypeEnum['Bump1'])
                  }
                  className={getStrikeStyle(PhaseTypeEnum['Bump1'])}
                >
                  {getResourceAllocated(
                    PhaseTypeEnum['Bump1'],
                    theJob,
                    getPhase,
                    true,
                  )}
                </Typography>
                {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                  (isBumpoutTimesheetFlow
                    ? !isBump1Approved
                    : isBump1Approved) &&
                  getResourceAllocated(PhaseTypeEnum['Bump1'], theJob, getPhase)
                    .length > 0 && (
                    <EditIcon
                      onClick={() => selectJobForUpdate(PhaseTypeEnum['Bump1'])}
                      className={classes.editIcon}
                    />
                  )}
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getStartDate(PhaseTypeEnum['Bump1'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getApprovedDate(PhaseTypeEnum['Bump1'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="10%"
                minWidth="10%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getSupervisorApproved(
                    PhaseTypeEnum['Bump1'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              {!isBumpoutTimesheetFlow && (
                <Box
                  display="flex"
                  width="8%"
                  minWidth="8%"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {showCameraPhase(PhaseTypeEnum['Bump1'], theJob, getPhase) &&
                    hasPermission(CAN_APPROVE_PHASE) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.buttonTimeline}
                        onClick={() => {
                          setIsViewPhotos(false);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump1,
                            ),
                          );
                        }}
                      >
                        Approve
                      </Button>
                    )}
                  {!showCameraPhase(PhaseTypeEnum['Bump1'], theJob, getPhase) &&
                    showPhaseImages(
                      PhaseTypeEnum['Bump1'],
                      theJob,
                      getPhase,
                    ) && (
                      <CameraAltIcon
                        className={classes.pointer}
                        onClick={() => {
                          setIsViewPhotos(true);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump1,
                            ),
                          );
                        }}
                      />
                    )}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              mt={2}
              pb={2}
              className={classes.borderGrid}
            >
              <Box
                display="flex"
                width="15%"
                minWidth="15%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>BumpOut 2</Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getResourceRequested(
                    PhaseTypeEnum['Bump2'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="18%"
                minWidth="18%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  onClick={
                    isBumpoutTimesheetFlow
                      ? undefined
                      : () => openJobPhaseHistoryModal(PhaseTypeEnum['Bump2'])
                  }
                  className={getStrikeStyle(PhaseTypeEnum['Bump2'])}
                >
                  {getResourceAllocated(
                    PhaseTypeEnum['Bump2'],
                    theJob,
                    getPhase,
                    true,
                  )}
                </Typography>
                {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                  (isBumpoutTimesheetFlow
                    ? !isBump2Approved
                    : isBump2Approved) &&
                  getResourceAllocated(PhaseTypeEnum['Bump2'], theJob, getPhase)
                    .length > 0 && (
                    <EditIcon
                      onClick={() => selectJobForUpdate(PhaseTypeEnum['Bump2'])}
                      className={classes.editIcon}
                    />
                  )}
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getStartDate(PhaseTypeEnum['Bump2'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getApprovedDate(PhaseTypeEnum['Bump2'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="10%"
                minWidth="10%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getSupervisorApproved(
                    PhaseTypeEnum['Bump2'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              {!isBumpoutTimesheetFlow && (
                <Box
                  display="flex"
                  width="8%"
                  minWidth="8%"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {showCameraPhase(PhaseTypeEnum['Bump2'], theJob, getPhase) &&
                    hasPermission(CAN_APPROVE_PHASE) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.buttonTimeline}
                        onClick={() => {
                          setIsViewPhotos(false);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump2,
                            ),
                          );
                        }}
                      >
                        Approve
                      </Button>
                    )}
                  {!showCameraPhase(PhaseTypeEnum['Bump2'], theJob, getPhase) &&
                    showPhaseImages(
                      PhaseTypeEnum['Bump2'],
                      theJob,
                      getPhase,
                    ) && (
                      <CameraAltIcon
                        className={classes.pointer}
                        onClick={() => {
                          setIsViewPhotos(true);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump2,
                            ),
                          );
                        }}
                      />
                    )}
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              width="100%"
              mt={2}
              pb={2}
              className={classes.borderGrid}
            >
              <Box
                display="flex"
                width="15%"
                minWidth="15%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>BumpOut 3</Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getResourceRequested(
                    PhaseTypeEnum['Bump3'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="18%"
                minWidth="18%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  onClick={
                    isBumpoutTimesheetFlow
                      ? undefined
                      : () => openJobPhaseHistoryModal(PhaseTypeEnum['Bump3'])
                  }
                  className={getStrikeStyle(PhaseTypeEnum['Bump3'])}
                >
                  {getResourceAllocated(
                    PhaseTypeEnum['Bump3'],
                    theJob,
                    getPhase,
                    true,
                  )}
                </Typography>
                {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                  (isBumpoutTimesheetFlow
                    ? !isBump3Approved
                    : isBump3Approved) &&
                  getResourceAllocated(PhaseTypeEnum['Bump3'], theJob, getPhase)
                    .length > 0 && (
                    <EditIcon
                      onClick={() => selectJobForUpdate(PhaseTypeEnum['Bump3'])}
                      className={classes.editIcon}
                    />
                  )}
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getStartDate(PhaseTypeEnum['Bump3'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="16%"
                minWidth="16%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getApprovedDate(PhaseTypeEnum['Bump3'], theJob, getPhase)}
                </Typography>
              </Box>
              <Box
                display="flex"
                width="10%"
                minWidth="10%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {getSupervisorApproved(
                    PhaseTypeEnum['Bump3'],
                    theJob,
                    getPhase,
                  )}
                </Typography>
              </Box>
              {!isBumpoutTimesheetFlow && (
                <Box
                  display="flex"
                  width="8%"
                  minWidth="8%"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {showCameraPhase(PhaseTypeEnum['Bump3'], theJob, getPhase) &&
                    hasPermission(CAN_APPROVE_PHASE) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.buttonTimeline}
                        onClick={() => {
                          setIsViewPhotos(false);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump3,
                            ),
                          );
                        }}
                      >
                        Approve
                      </Button>
                    )}
                  {!showCameraPhase(PhaseTypeEnum['Bump3'], theJob, getPhase) &&
                    showPhaseImages(
                      PhaseTypeEnum['Bump3'],
                      theJob,
                      getPhase,
                    ) && (
                      <CameraAltIcon
                        className={classes.pointer}
                        onClick={() => {
                          setIsViewPhotos(true);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              PhaseTypeEnum.Bump3,
                            ),
                          );
                        }}
                      />
                    )}
                </Box>
              )}
            </Box>
            {showBump(PhaseTypeEnum.Bump4) && (
              <Box
                display="flex"
                width="100%"
                mt={2}
                pb={2}
                className={classes.borderGrid}
              >
                <Box
                  display="flex"
                  width="15%"
                  minWidth="15%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>BumpOut 4</Typography>
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getResourceRequested(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="18%"
                  minWidth="18%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    onClick={
                      isBumpoutTimesheetFlow
                        ? undefined
                        : () => openJobPhaseHistoryModal(PhaseTypeEnum['Bump4'])
                    }
                    className={getStrikeStyle(PhaseTypeEnum['Bump4'])}
                  >
                    {getResourceAllocated(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                      true,
                    )}
                  </Typography>
                  {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                    (isBumpoutTimesheetFlow
                      ? !isBump4Approved
                      : isBump4Approved) &&
                    getResourceAllocated(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                    ).length > 0 && (
                      <EditIcon
                        onClick={() =>
                          selectJobForUpdate(PhaseTypeEnum['Bump4'])
                        }
                        className={classes.editIcon}
                      />
                    )}
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getStartDate(PhaseTypeEnum['Bump4'], theJob, getPhase)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getApprovedDate(PhaseTypeEnum['Bump4'], theJob, getPhase)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="10%"
                  minWidth="10%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getSupervisorApproved(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                    )}
                  </Typography>
                </Box>
                {!isBumpoutTimesheetFlow && (
                  <Box
                    display="flex"
                    width="8%"
                    minWidth="8%"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {showCameraPhase(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                    ) &&
                      hasPermission(CAN_APPROVE_PHASE) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.buttonTimeline}
                          onClick={() => {
                            setIsViewPhotos(false);
                            setUploadModalOpen(true);
                            setSelectedPhase(
                              getPhase(
                                theJob.job,
                                theJob.phases,
                                theJob.jobPhases,
                                PhaseTypeEnum.Bump4,
                              ),
                            );
                          }}
                        >
                          Approve
                        </Button>
                      )}
                    {!showCameraPhase(
                      PhaseTypeEnum['Bump4'],
                      theJob,
                      getPhase,
                    ) &&
                      showPhaseImages(
                        PhaseTypeEnum['Bump4'],
                        theJob,
                        getPhase,
                      ) && (
                        <CameraAltIcon
                          className={classes.pointer}
                          onClick={() => {
                            setIsViewPhotos(true);
                            setUploadModalOpen(true);
                            setSelectedPhase(
                              getPhase(
                                theJob.job,
                                theJob.phases,
                                theJob.jobPhases,
                                PhaseTypeEnum.Bump4,
                              ),
                            );
                          }}
                        />
                      )}
                  </Box>
                )}
              </Box>
            )}
            {showBump(PhaseTypeEnum.Bump5) && (
              <Box
                display="flex"
                width="100%"
                mt={2}
                pb={2}
                className={classes.borderGrid}
              >
                <Box
                  display="flex"
                  width="15%"
                  minWidth="15%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>BumpOut 5</Typography>
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getResourceRequested(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="18%"
                  minWidth="18%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    onClick={
                      isBumpoutTimesheetFlow
                        ? undefined
                        : () => openJobPhaseHistoryModal(PhaseTypeEnum['Bump5'])
                    }
                    className={getStrikeStyle(PhaseTypeEnum['Bump5'])}
                  >
                    {getResourceAllocated(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                      true,
                    )}
                  </Typography>
                  {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                    (isBumpoutTimesheetFlow
                      ? !isBump5Approved
                      : isBump5Approved) &&
                    getResourceAllocated(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                    ).length > 0 && (
                      <EditIcon
                        onClick={() =>
                          selectJobForUpdate(PhaseTypeEnum['Bump5'])
                        }
                        className={classes.editIcon}
                      />
                    )}
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getStartDate(PhaseTypeEnum['Bump5'], theJob, getPhase)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="16%"
                  minWidth="16%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getApprovedDate(PhaseTypeEnum['Bump5'], theJob, getPhase)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  width="10%"
                  minWidth="10%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography>
                    {getSupervisorApproved(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                    )}
                  </Typography>
                </Box>
                {!isBumpoutTimesheetFlow && (
                  <Box
                    display="flex"
                    width="8%"
                    minWidth="8%"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {showCameraPhase(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                    ) &&
                      hasPermission(CAN_APPROVE_PHASE) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          className={classes.buttonTimeline}
                          onClick={() => {
                            setIsViewPhotos(false);
                            setUploadModalOpen(true);
                            setSelectedPhase(
                              getPhase(
                                theJob.job,
                                theJob.phases,
                                theJob.jobPhases,
                                PhaseTypeEnum.Bump5,
                              ),
                            );
                          }}
                        >
                          Approve
                        </Button>
                      )}
                    {!showCameraPhase(
                      PhaseTypeEnum['Bump5'],
                      theJob,
                      getPhase,
                    ) &&
                      showPhaseImages(
                        PhaseTypeEnum['Bump5'],
                        theJob,
                        getPhase,
                      ) && (
                        <CameraAltIcon
                          className={classes.pointer}
                          onClick={() => {
                            setIsViewPhotos(true);
                            setUploadModalOpen(true);
                            setSelectedPhase(
                              getPhase(
                                theJob.job,
                                theJob.phases,
                                theJob.jobPhases,
                                PhaseTypeEnum.Bump5,
                              ),
                            );
                          }}
                        />
                      )}
                  </Box>
                )}
              </Box>
            )}
          </Fragment>
        )}
      </Box>

      <ContractorChangeDialog
        regionId={theJob.subdivision.regionId}
        handleClose={() => setOpenContractorModal(false)}
        open={openContractorModal}
        refreshJobPhases={refreshJobPhases}
        selectedJobPhase={selectedJobPhase}
        job={theJob}
      />
      <ContractorHistoryDialog
        handleClose={() => setOpenHistoryModal(false)}
        open={openHistoryModal}
        selectedJobPhase={selectedJobPhase}
      />
      <ConfirmRequestDialog
        open={openConfirmRequest}
        handleClose={() => setOpenConfirmRequest(false)}
        selectedJobPhase={theJob.jobPhase}
        onConfirm={onConfirm}
        job={theJob}
        regionId={theJob.subdivision.regionId}
      />
      <ConfirmDialog
        open={openInitialDate}
        title=""
        content={
          <>
            <p>
              Initial Start date: {new Date(initialDate).toLocaleDateString()}
            </p>
            {changedBySupervisor && <p>Note: Supervisor adjusted date</p>}
          </>
        }
        handleClose={() => setOpenInitialDate(false)}
      />
      <ConfirmDialog
        open={openInitialFinishingDate}
        title=""
        content={
          <>
            <p>
              Initial Start date:{' '}
              {new Date(initialFinishingDate).toLocaleDateString()}
            </p>
            {changedBySupervisorFinishing && (
              <p>Note: Supervisor adjusted date</p>
            )}
          </>
        }
        handleClose={() => setOpenInitialFinishingDate(false)}
      />
    </Box>
  );
};

export default MasterSheetTimeline;
