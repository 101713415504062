/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {
  Fragment,
  useState,
  cloneElement,
  FC,
  ChangeEvent,
  ReactElement,
} from 'react';
import {
  List,
  TextField,
  useDataProvider,
  useRefresh,
  useNotify,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ExportButton,
  ListProps,
  PublicFieldProps,
  InjectedFieldProps,
  DateField,
  ToolbarProps,
  ExportButtonProps,
} from 'react-admin';
import Input from '@material-ui/core/Input';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InfoDialog from 'components/InfoDialog';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ConfirmDialog from 'components/ConfirmDialog';
import { Show } from 'components/Show';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Datagrid from 'components/Datagrid';
import { ProdPayMasterSheetFilter } from '../filters/prod-pay-master-sheet-filters';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { exporter } from 'providers/export';
import {
  JobPhaseDto,
  JobPhaseInterface,
  ProdPayMasterSheetInterface,
} from '@vatos-pas/common';
import ContractorHistoryDialog from '../../master-sheet/views/master-sheet-contractor-history';
import { CAN_PAID_PHASE, CAN_SEE_LOT_REPORT } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';

interface DefaultFieldsInterface {
  [id: string]: {
    costTotal: number;
    checkNumber: string;
    datePaid: MaterialUiPickersDate;
  };
}

const useStyles = makeStyles({
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  link: {
    backgroundColor: '#e6005a',
    height: '50px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    color: 'white',
  },
  pink: {
    backgroundColor: 'lightpink',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  normal: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  infoWarning: {
    color: '#ff9800',
  },
});

type CostFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (
    id: string,
  ) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const CostField: FC<CostFieldProps> = ({
  record,
  fields,
  canPaid,
  onChangeField,
}) => {
  if (!record) return null;

  const { id, costTotal } = record;
  const hasFieldCostTotal = fields[id] && fields[id]?.costTotal !== undefined;
  const costTotalText = costTotal ? costTotal : '';
  const value = hasFieldCostTotal ? fields[id].costTotal : costTotalText;
  return (
    <Box display="flex" minWidth={80}>
      <Input
        value={value}
        fullWidth
        type="number"
        disabled={!canPaid}
        onChange={onChangeField(id)}
      />
    </Box>
  );
};

type CheckFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const CheckField: FC<CheckFieldProps> = ({
  record,
  fields,
  canPaid,
  onChangeField,
}) => {
  if (!record) return null;

  const { id, checkNumber } = record;
  const hasFieldCheckNumber =
    fields[id] && fields[id]?.checkNumber !== undefined;
  const checkNumberText = checkNumber ? checkNumber : '';
  const value = hasFieldCheckNumber ? fields[id].checkNumber : checkNumberText;
  return (
    <Box display="flex" minWidth={80}>
      <Input
        value={value}
        fullWidth
        disabled={!canPaid}
        onChange={onChangeField(record.id)}
      />
    </Box>
  );
};

type OnChangeDateProps = {
  target: {
    value: MaterialUiPickersDate;
  };
};

type PaidDateFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (id: string) => (event: OnChangeDateProps) => void;
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const PaidDateField: FC<PaidDateFieldProps> = ({
  record,
  fields,
  canPaid,
  onChangeField,
}) => {
  if (!record) return null;

  const { id, datePaid } = record;
  const hasFieldDatePaid = fields[id] && fields[id]?.datePaid;
  const datePaidText = datePaid ? datePaid : null;
  const value = hasFieldDatePaid ? fields[id].datePaid : datePaidText;
  return (
    <Box display="flex" minWidth={120}>
      <KeyboardDatePicker
        disabled={!canPaid}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        fullWidth
        value={value}
        onChange={date => onChangeField(id)({ target: { value: date } })}
        KeyboardButtonProps={{ 'aria-label': 'change date' }}
      />
    </Box>
  );
};

type SubmitButtonProps = {
  fields: DefaultFieldsInterface;
  onClickSubmit: (record: ProdPayMasterSheetInterface) => () => void;
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const SubmitButton: FC<SubmitButtonProps> = ({
  record,
  fields,
  onClickSubmit,
}) => {
  if (!record) return null;

  const { id, isPaid, checkNumber, costTotal, datePaid } = record;

  const isDisabled = () => {
    const field = fields[id];
    if (isPaid) {
      return (
        ((field && field.checkNumber) ||
          (checkNumber && field && field.checkNumber === undefined)) &&
        ((field && field.costTotal) ||
          (costTotal && field && field.costTotal === undefined)) &&
        ((field && field.datePaid) ||
          (datePaid && field && field.datePaid === undefined))
      );
    }

    if (field) {
      return field.checkNumber && field.costTotal && field.datePaid;
    }

    return false;
  };

  return (
    <Button
      disabled={!isDisabled()}
      variant="contained"
      color="primary"
      onClick={onClickSubmit(record)}
    >
      {isPaid ? 'Update' : 'Submit'}
    </Button>
  );
};

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = ({
  className,
  filters,
  maxResults,
  ...rest
}) => {
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

type LotReportProps = {
  classes: { [key: string]: string };
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const LotReport: FC<LotReportProps> = ({ record, classes }) => {
  if (!record) return null;

  return (
    <Box className={!record.jobHasSpecialPayment ? '' : classes.link}>
      <Typography>
        <Link
          className={!record.jobHasSpecialPayment ? '' : classes.linkText}
          href={`${window.location.origin}/#/job-master-sheet/${record.jobId}`}
        >
          View
        </Link>
      </Typography>
    </Box>
  );
};

type ContractorFieldProps = {
  setOpenHistoryModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedJobPhasel: React.Dispatch<
    React.SetStateAction<
      | {
          jobPhaseContractorHistories: JobPhaseInterface['jobPhaseContractorHistories'];
        }
      | undefined
    >
  >;
  classes: { [key: string]: string };
} & PublicFieldProps &
  InjectedFieldProps<ProdPayMasterSheetInterface>;

const ContractorField: FC<ContractorFieldProps> = ({
  record,
  setOpenHistoryModal,
  setSelectedJobPhasel,
  classes,
}) => {
  if (!record) return null;

  const { jobPhaseContractorHistories, contractorName } = record;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openHistory = () => {
    setOpenHistoryModal(true);
    setSelectedJobPhasel({ jobPhaseContractorHistories });
  };

  const jobPhaseContractorHistoriesLength = jobPhaseContractorHistories?.length;

  return (
    <Box display="flex" alignItems="center" gridColumnGap={4}>
      <Typography
        className={
          jobPhaseContractorHistoriesLength &&
          jobPhaseContractorHistoriesLength > 1
            ? classes.pink
            : classes.normal
        }
        onClick={openHistory}
      >
        {contractorName}
      </Typography>
      <Show condition={record.contractorHasNotes}>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <InfoOutlinedIcon className={classes.infoWarning} />
        </IconButton>
      </Show>
      <InfoDialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Contractor Notes"
        content={
          <>
            {record.contractorHasNotes && (
              <Typography>{record.contractorNotes}</Typography>
            )}
          </>
        }
      />
    </Box>
  );
};

export const ProdPayMasterSheetList: FC<ListProps> = props => {
  const [fields, setFields] = useState<DefaultFieldsInterface>({});
  const [idSelected, setIdSelected] = useState<JobPhaseDto['id']>('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [selectedJobPhase, setSelectedJobPhasel] = useState<{
    jobPhaseContractorHistories: JobPhaseInterface['jobPhaseContractorHistories'];
  }>();
  const { opened } = UIProvider.useContainer();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles(props);

  const { hasPermission } = PermissionsProvider.useContainer();

  const canPaid = hasPermission(CAN_PAID_PHASE);
  const onClose = () => setOpenConfirm(false);

  const onChangeField =
    (field: string) =>
    (id: string) =>
    (
      event:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | OnChangeDateProps,
    ) => {
      setFields({
        ...fields,
        [id]: { ...fields[id], [field]: event.target.value },
      });
    };

  const submitCost = record => () => {
    setIdSelected(record.id);
    setOpenConfirm(true);
  };

  const onConfirmSubmit = async () => {
    try {
      const jobPhaseFound = await dataProvider.getOne<JobPhaseInterface>(
        'job-phase',
        {
          id: idSelected,
        },
      );

      const costTotal =
        fields[idSelected] && fields[idSelected].costTotal
          ? parseFloat(fields[idSelected]?.costTotal.toString())
          : parseFloat(jobPhaseFound.data.costTotal?.toString() || '');

      const checkNumber =
        fields[idSelected] && fields[idSelected].checkNumber
          ? fields[idSelected].checkNumber
          : jobPhaseFound.data.checkNumber;

      const datePaid =
        fields[idSelected] && fields[idSelected].datePaid
          ? fields[idSelected]?.datePaid?.toISO()
          : jobPhaseFound.data.datePaid;

      const params = {
        id: idSelected,
        data: { costTotal, checkNumber, datePaid },
        previousData: jobPhaseFound.data,
      };

      await dataProvider.update('job-phase', params);
      notify('Changes were made successfully!');
      refresh();
    } catch {
      notify(`An error has occurred!`, 'warning');
    }
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <List
        classes={{
          content: opened ? classes.content : classes.contentClosed,
          root: opened ? classes.root : classes.rootClosed,
        }}
        {...props}
        exporter={exporter('prod-pay-master-sheet')}
        filters={
          <ProdPayMasterSheetFilter>{props.filters}</ProdPayMasterSheetFilter>
        }
        filterDefaultValues={{ isPaid: false }}
        sort={{ field: 'dateApproved', order: 'ASC' }}
        bulkActionButtons={<React.Fragment />}
        actions={<ListActions maxResults={20000} />}
      >
        <Datagrid>
          <TextField source="supervisorFullName" label="Supervisor" />
          <TextField source="regionName" label="Region" />
          <TextField source="builderName" label="Builder" />
          <TextField source="subdivisionName" label="Subdivision" />
          <TextField source="jobLot" label="Lot" />
          <TextField source="jobBlock" label="Block" />
          <TextField source="phaseName" label="Phase" />
          <ContractorField
            setSelectedJobPhasel={setSelectedJobPhasel}
            label="Contractor"
            setOpenHistoryModal={setOpenHistoryModal}
            classes={classes}
          />
          <TextField
            source="payableBoardsOrderedTotal"
            label="Payable Boards"
          />
          <TextField
            source="payableBoardsReceiptTotal"
            label="Supervisor Count"
          />
          <TextField source="payableOverride" label="Payable Override" />
          {hasPermission(CAN_SEE_LOT_REPORT) && (
            <LotReport classes={classes} label="Lot Report" />
          )}
          <DateField source="dateApproved" label="Completion Date" />
          <CostField
            canPaid={canPaid}
            onChangeField={onChangeField('costTotal')}
            fields={fields}
            label="Total Cost"
          />
          <CheckField
            canPaid={canPaid}
            onChangeField={onChangeField('checkNumber')}
            fields={fields}
            label="Check #"
          />
          <PaidDateField
            canPaid={canPaid}
            onChangeField={onChangeField('datePaid')}
            fields={fields}
            label="Paid Date"
          />
          <SubmitButton fields={fields} onClickSubmit={submitCost} />
        </Datagrid>
      </List>
      <ConfirmDialog
        open={openConfirm}
        title={`Are you sure that you want to make this change?`}
        handleClose={onClose}
        onConfirm={onConfirmSubmit}
      />
      <ContractorHistoryDialog
        handleClose={() => setOpenHistoryModal(false)}
        open={openHistoryModal}
        selectedJobPhase={selectedJobPhase}
        setOpenHistoryModal={setOpenHistoryModal}
      />
    </Fragment>
  );
};
