import React, { useEffect, useState } from 'react';
import {
  GetListResult,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ArrowIcon from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import { luxon } from 'services/luxon';
import CustomReference from 'components/CustomReference';
import DateSelect from 'components/DateSelect';
import { useStyles } from './schedule-styles';
import { JobMasterSheetDto, PhaseTypeEnum } from '@vatos-pas/common';

interface Schedule extends JobMasterSheetDto {
  id: string;
  subdivisionName: string;
  buildingId: string;
  buildingNumber: string;
  jobLot: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  state: string;
  currentPhaseDateRequested: Date | null;
  builderName: string;
  payableBoardsTotal: number;
}

const from = new Date();
const to = luxon.addDays(luxon.date(from), 90).toBSON();

const earnMarkPhases = [
  'Spraying',
  'Bump 1',
  'Bump 2',
  'Bump 3',
  'Bump 4',
  'Bump 5',
];

export const ScheduleList: React.FC = () => {
  const [date, setDate] = useState(new Date());
  const [phase, setPhase] = useState('');
  const [loading, setLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const classes = useStyles();

  const getSchedules = async () => {
    if (!phase.length) return;

    const supervisorId = localStorage.getItem('userId');

    if (!supervisorId) return;

    try {
      setLoading(true);

      const formattedDate = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0'),
      ].join('-');

      const schedulesData: GetListResult<Schedule> = await dataProvider.getList(
        'job-master-sheet',
        {
          filter: {
            'currentPhaseDateRequested||$gt': formattedDate + 'T00:00',
            'currentPhaseDateRequested||$lt': formattedDate + 'T23:59',
            'currentPhaseId||$eq': phase,
            'supervisorUserId||$eq': supervisorId,
          },
          pagination: { page: 1, perPage: 999 },
          sort: { field: 'currentPhaseDateRequested', order: 'DESC' },
        },
      );

      if (!schedulesData.data) {
        throw new Error('Schedule not found');
      }

      setSchedules(schedulesData.data);
    } catch (error) {
      notify('Failed to load schedule', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSchedules();
  }, [phase, date]);

  const isDataEmpty = schedules.length === 0;

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        <Box width="100%" maxWidth="100vw">
          <Box margin="0 8px">
            <CustomReference
              label="Phase"
              value={phase}
              resource="phase"
              sort={{ field: 'priority', order: 'ASC' }}
              filters={{ 'priority||$gte': 200, 'priority||$lte': 500 }}
              onLoad={data => {
                const initialPhase = data.find(
                  (p: any) => p.phaseType === PhaseTypeEnum.Spraying,
                );

                if (initialPhase && phase === '') {
                  setPhase(String(initialPhase.id));
                }
              }}
              onChange={event => {
                const { value } = event.target;
                if (typeof value === 'string' && value !== phase) {
                  setPhase(value);
                }
              }}
            />
            <DateSelect
              to={to}
              from={from}
              selected={date}
              onSelect={setDate}
            />
          </Box>
        </Box>
        {loading && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={12}
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {!loading && isDataEmpty && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={12}
          >
            <Typography>No results found</Typography>
          </Box>
        )}
        {!loading &&
          !isDataEmpty &&
          schedules.map(schedule => (
            <Box
              key={schedule.id}
              display="flex"
              flexDirection="row"
              className={classes.whiteBox}
              boxShadow={3}
              style={{
                backgroundColor:
                  earnMarkPhases.indexOf(schedule.currentPhaseName) !== -1 &&
                  schedule.currentPhaseContractorId &&
                  !schedule.currentPhaseApproved
                    ? '#ffc107'
                    : schedule.currentPhaseIsOnTime && !schedule.pendingReview
                    ? 'white'
                    : schedule.pendingReview
                    ? '#ff9900'
                    : '#ff8282',
              }}
              onClick={() => redirect(`/job-master-sheet/${schedule.id}`)}
            >
              <Box
                display="flex"
                flexDirection="column"
                flex={0.5}
                className={classes.info}
              >
                <Box>
                  <Typography className={classes.label}>Subdivision</Typography>
                  <Typography>{schedule.subdivisionName}</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex={1}>
                    <Typography className={classes.label}>
                      Building #
                    </Typography>
                    <Typography>
                      {schedule?.buildingNumber?.padStart(3, '0')}
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography className={classes.label}>Lot</Typography>
                    <Typography>
                      {schedule?.jobLot?.padStart(4, '0')}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className={classes.label}>Address</Typography>
                  <Typography>{schedule.address1}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                flex={0.4}
                className={classes.info}
              >
                <Box>
                  <Typography className={classes.label}>Start Date</Typography>
                  <Typography>
                    {schedule.currentPhaseDateRequested
                      ? luxon
                          .date(new Date(schedule.currentPhaseDateRequested))
                          .toFormat('MM/dd/yyyy')
                      : ''}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.label}>Builder</Typography>
                  <Typography>{schedule.builderName}</Typography>
                </Box>
                <Box>
                  <Typography className={classes.label}>
                    Payable Boards
                  </Typography>
                  <Typography>{schedule.payableBoardsTotal}</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flex={0.1}
                alignItems="center"
                justifyContent="center"
              >
                <ArrowIcon />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default ScheduleList;
