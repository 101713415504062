import React, { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Filter,
  FilterProps,
  ReferenceInput,
  TextInput,
} from 'react-admin';

type Props = {
  showSupplier?: boolean;
} & Omit<FilterProps, 'children'>;

export const MaterialDeliveryFilter: FC<Props> = ({
  showSupplier = true,
  ...props
}) => {
  const ScreenSize = window.screen.width;
  return (
    <Filter {...props}>
      {showSupplier && (
        <ReferenceInput
          label="Supplier"
          source="supplierId"
          reference="supplier"
          resettable
          data-cy="MaterialDeliveryFilterSupplier"
          allowEmpty={false}
          filterToQuery={name => (name ? { name } : {})}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      )}
      <TextInput
        label="Purchase Order #"
        source="orderNumber||$cont"
        resettable
        data-cy="MaterialDeliveryFilterPurchaseOrder"
        alwaysOn
      />
      <ReferenceInput
        label="Builders"
        source="builderId"
        reference="builder"
        resettable
        data-cy="MaterialDeliveryFilterBuilder"
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Subdivision"
        source="subdivisionId"
        reference="subdivision"
        data-cy="MaterialDeliveryFilterSubdivision"
        resettable
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput
        label="Lot"
        source="jobLot||$eq"
        resettable
        data-cy="MaterialDeliveryFilterLot"
        alwaysOn
      />
      <TextInput
        label="Address"
        source="fullAddress||$contL"
        resettable
        data-cy="MaterialDeliveryFilterAddress"
        alwaysOn
      />
      <DateInput
        style={{ width: ScreenSize <= 849 && ScreenSize >= 200 ? 280 : '100%' }}
        label="Expected Date"
        source="dateExpected||$eq"
        data-cy="MaterialDeliveryFilterDateExpected"
        alwaysOn
      />
      <DateInput
        style={{ width: ScreenSize <= 849 && ScreenSize >= 200 ? 280 : '100%' }}
        label="Promised Date"
        source="datePromised||$eq"
        placeholder="dd/mm/yyyy"
        data-cy="MaterialDeliveryFilterDatePromised"
        alwaysOn
      />
      <DateInput
        style={{ width: ScreenSize <= 849 && ScreenSize >= 200 ? 280 : '100%' }}
        label="Delivery Date"
        source="dateDelivery||$eq"
        data-cy="MaterialDeliveryFilterDateDelivery"
        alwaysOn
      />
      <BooleanInput
        source="noPromissedDate||$eq"
        label="No Promised Date"
        data-cy="MaterialDeliveryFilterPromisedDate"
        defaultValue={false}
        alwaysOn
      />
      <BooleanInput
        source="noDeliveryDate||$eq"
        label="No Delivery Date"
        data-cy="MaterialDeliveryFilterDeliveryDate"
        defaultValue={false}
        alwaysOn
      />
      <BooleanInput
        source="late||$eq"
        label="Late"
        data-cy="MaterialDeliveryFilterPromisedDate"
        defaultValue={false}
        alwaysOn
      />
    </Filter>
  );
};
