import React, { useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { SubdivisionDto } from '@vatos-pas/common';
import { useStyles } from './../master-sheet-styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CHECK_UNCHECK_HOUSE } from 'providers/permissions';

import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  bullet: {
    width: '8px',
    height: '8px',
    background: '#bdbdbd  ',
    margin: '2px',
    borderRadius: '50%',

    '&.active': {
      background: '#3aa1f9',
    },
  },
});

interface MasterSheetJobInfoProps {
  theJob: any;
  checkHouse: any;
}

const getSubdivisionAddressInfo = (subdivision: SubdivisionDto) => {
  if (!subdivision) return '';

  return `${subdivision.city} - ${subdivision.state} - ${subdivision.zip}`;
};

export const MasterSheetJobInfo: React.FC<MasterSheetJobInfoProps> = ({
  theJob,
  checkHouse,
}) => {
  const classes = useStyles();
  const innerStyles = styles();

  const inputProps = {
    InputLabelProps: {
      shrink: true,
      className: classes.black,
    },
    InputProps: {
      className: classes.black,
    },
  };
  const { hasPermission } = PermissionsProvider.useContainer();

  const [actualPanel, setActualPanel] = useState<number>(1);

  const getPanelVisibility = (panelNumber: number) => {
    return panelNumber === actualPanel ? 'block' : 'none';
  };

  const toogleActualPanel = (panelNumber: number) => {
    if (panelNumber !== actualPanel) {
      setActualPanel(panelNumber);
    }
  };

  const getBulletActive = (panelNumber: number): string => {
    if (panelNumber === actualPanel) {
      return `${innerStyles.bullet} active`;
    }
    return innerStyles.bullet;
  };

  const currentSupplier = useMemo(() => {
    const latestPO = theJob?.boardPurchases?.at(-1) || {};
    const supplierName = latestPO?.supplier?.name;
    return supplierName;
  }, [theJob]);

  return (
    <>
      <Box m={1}>
        <Box display="flex" width="100%" flexDirection="row">
          <Box width="50%" display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={theJob.job.houseMarked}
                  onChange={(event: any) => checkHouse(event.target.checked)}
                  name="isMonday"
                  disabled={
                    !hasPermission(CAN_CHECK_UNCHECK_HOUSE) ||
                    theJob.job.houseMarked
                  }
                  color="primary"
                />
              }
              label="Mark House"
            />
          </Box>
          <Box width="50%">
            {theJob.job.dateHouseMarked && (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="House marked on"
                fullWidth
                onChange={() => console.log()}
                // className={classes.dateStarted}
                value={new Date(theJob.job.dateHouseMarked)}
                disabled
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.whiteBoxMobile}>
        <Box m={3}>
          <Box
            display={getPanelVisibility(1)}
            onClick={() => {
              toogleActualPanel(2);
            }}
            width="100%"
          >
            <Box>
              <Box width="100%" display="flex" alignItems="flex-end">
                <TextField
                  label="Subdivision"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.input}
                  value={theJob.subdivision.name}
                />

                <TextField
                  label="Builder"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.inputNoMarginRight}
                  value={theJob.builder.name}
                />
              </Box>

              <Box width="100%" display="flex" alignItems="flex-end">
                <TextField
                  label="Current Supplier"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.input}
                  value={currentSupplier}
                />
              </Box>

              <Box width="100%" display="flex" alignItems="flex-end">
                <TextField
                  label="Building #"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.input}
                  value={theJob.job.building && theJob.job.building.number}
                />
                <TextField
                  label="Lot"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.inputNoMarginRight}
                  value={theJob.job.lot}
                />
              </Box>

              <TextField
                label="Address"
                fullWidth
                {...inputProps}
                disabled
                value={theJob.job.address1}
                className={classes.input}
              />
              <TextField
                label="City, State, Zip"
                fullWidth
                {...inputProps}
                disabled
                className={classes.input}
                value={getSubdivisionAddressInfo(theJob.job)}
              />
            </Box>
          </Box>

          <Box
            display={getPanelVisibility(2)}
            onClick={() => {
              toogleActualPanel(1);
            }}
            width="100%"
          >
            <Box>
              <TextField
                label="Supervisor"
                fullWidth
                {...inputProps}
                disabled
                className={classes.input}
                value={`${theJob.supervisorUser.firstName} ${theJob.supervisorUser.lastName}`}
              />
              <Box width="100%" display="flex" alignItems="flex-end">
                <TextField
                  label="Job ID #"
                  fullWidth
                  {...inputProps}
                  disabled
                  value={theJob.job.idNumber}
                  className={classes.input}
                />
                <TextField
                  label="Model"
                  fullWidth
                  {...inputProps}
                  disabled
                  className={classes.inputNoMarginRight}
                  value={theJob.job.model && theJob.job.model.name}
                />
              </Box>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Project Approval - Due Date"
                fullWidth
                disabled
                className={classes.input}
                value={theJob.job.dateDue}
                onChange={(date: MaterialUiPickersDate) => date}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                {...inputProps}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display="flex" width="100%" justifyContent="center">
        <div className={getBulletActive(1)}></div>
        <div className={getBulletActive(2)}></div>
      </Box>

      <Box mt={2} mb={4} px={2}>
        <Box className={classes.titleTab}>
          <Typography className={classes.titleFont}>Options</Typography>
        </Box>
        {theJob.job.options && (
          <div
            className="preview"
            dangerouslySetInnerHTML={{
              __html: theJob.job.options,
            }}
          ></div>
        )}
      </Box>
    </>
  );
};

export default MasterSheetJobInfo;
