import React, { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  NullableBooleanInput,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
} from 'react-admin';
import { UserDto } from '@vatos-pas/common';
import Box from '@material-ui/core/Box';
import { AppRole } from 'providers/roles';
import { useStyles } from '../views/master-sheet-styles';
import { PermissionsProvider } from 'providers/permissionsProvider';

export const MasterSheetFilters: FC<any> = props => {
  const { hasRole } = PermissionsProvider.useContainer();

  const isSupervisor = hasRole(AppRole.Supervisor);

  const isWarranty = window.location.href.indexOf('job-warranty-sheet') !== -1;

  const classes = useStyles();
  return (
    <Box>
      <Filter {...props}>
        {!isSupervisor && (
          <ReferenceInput
            label="Supervisor"
            source="supervisorUserId"
            reference="user"
            resettable
            data-cy="masterSheetFilterSupervisor"
            alwaysOn
            allowEmpty={false}
            filterToQuery={value => {
              if (value) {
                return {
                  s: {
                    $and: [
                      {
                        $or: [
                          { firstName: { $cont: value } },
                          { lastName: { $cont: value } },
                        ],
                      },
                      {
                        'userRoles.role.name': { $eq: AppRole.Supervisor },
                      },
                    ],
                  },
                };
              } else {
                return {
                  s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
                };
              }
            }}
            sort={{ field: 'firstName', order: 'ASC' }}
          >
            <AutocompleteInput
              optionText={(user: UserDto) =>
                `${user.firstName} ${user.lastName}`
              }
              optionValue="id"
              suggestionLimit={25}
            />
          </ReferenceInput>
        )}
        <TextInput
          label="Lot"
          source="jobLot||$eq"
          alwaysOn
          resettable
          data-cy="masterSheetFilterLot"
        />
        <ReferenceInput
          label="Builder"
          source="builderId"
          reference="builder"
          resettable
          data-cy="masterSheetFilterBuilder"
          alwaysOn
          allowEmpty={false}
          filterToQuery={value => {
            if (value) {
              return { name: value };
            } else {
              return {};
            }
          }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput
            allowEmpty={false}
            optionText="name"
            optionValue="id"
            suggestionLimit={25}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Subdivision"
          source="subdivisionId"
          reference="subdivision"
          resettable
          data-cy="masterSheetFilterSubdivision"
          alwaysOn
          allowEmpty={false}
          filterToQuery={value => {
            if (value) {
              return { name: value };
            } else {
              return {};
            }
          }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          label="Phase"
          source="currentPhaseId"
          perPage={Number.MAX_SAFE_INTEGER}
          reference="phase"
          resettable
          alwaysOn
          data-cy="masterSheetFilterPhase"
          allowEmpty={false}
          sort={{ field: 'priority', order: 'ASC' }}
          filter={{
            'priority||$gte': props.isWarranty ? 600 : 0,
            'priority||$lte': props.isWarranty ? 1000 : 500,
          }}
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <BooleanInput
          source="currentPhaseIsOnTime||$eq"
          label="Phase On Time"
          data-cy="masterSheetFilterPhaseOnTime"
        />
        <BooleanInput
          source="currentPhaseRequestIsOnTime||$eq"
          label="Request On Time"
          data-cy="masterSheetFilterRequestOnTime"
        />
        <BooleanInput
          source="currentPhaseApprovalIsOnTime||$eq"
          label="Approval On Time"
          data-cy="masterSheetFilterApprovalOnTime"
        />
        <DateInput
          className={classes.filterDate}
          label="Res. Req. On or After"
          source="currentPhaseDateRequested||$gt"
        />
        <DateInput
          className={classes.filterDate}
          label="Res. Req. On or Before"
          source="currentPhaseDateRequested||$lt"
        />
        {!isWarranty && (
          <NullableBooleanInput
            source="activeJob||$eq"
            label="Active Jobs"
            alwaysOn
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
            nullLabel="All"
            falseLabel="Inactive"
            trueLabel="Active"
            data-cy="masterSheetFilterActiveJob"
          />
        )}
        {!isWarranty && (
          <NullableBooleanInput
            source="preRock||$eq"
            label="Pre-Rock"
            alwaysOn
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
            nullLabel="All"
            falseLabel="No"
            trueLabel="Yes"
            data-cy="masterSheetFilterActivesJob"
          />
        )}
        {!isWarranty && (
          <ReferenceInput
            label="Contractor"
            // contractorId is not used to filter.
            // Instead, we hook into the request in the dataProvider
            // and apply the correct filters.
            source="contractorId"
            reference="contractor"
            data-cy="masterSheetFilterContractor"
            sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={value => {
              if (value) {
                return {
                  s: {
                    $and: [
                      {
                        name: { $cont: value },
                      },
                    ],
                  },
                };
              }
            }}
            allowEmpty={false}
            resettable
          >
            <AutocompleteInput
              optionText="name"
              optionValue="id"
              suggestionLimit={25}
            />
          </ReferenceInput>
        )}
        <TextInput
          label="Address"
          alwaysOn
          resettable
          source="address1||$cont"
          data-cy="address1Filter"
        />
      </Filter>
    </Box>
  );
};
