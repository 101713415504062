import React, { FC } from 'react';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  FilterProps,
} from 'react-admin';
import { UserDto } from '@vatos-pas/common';
import { AppRole } from 'providers/roles';

export const RepairReceivablesSheetFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Supervisor"
      source="supervisorUserId"
      reference="user"
      resettable
      alwaysOn
      data-cy="RepairReceivablesSheetFilterSupervisor"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: {
              $and: [
                {
                  $or: [
                    { firstName: { $cont: value } },
                    { lastName: { $cont: value } },
                  ],
                },
                {
                  'userRoles.role.name': { $eq: AppRole.Supervisor },
                },
              ],
            },
          };
        } else {
          return {
            s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
          };
        }
      }}
      sort={{ field: 'firstName', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(user: UserDto) => {
          return `${user.firstName} ${user.lastName}`;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Repair Type"
      data-cy="RepairReceivablesSheetFilterPhase"
      source="repairId"
      reference="repair"
      perPage={Number.MAX_SAFE_INTEGER}
      resettable
      alwaysOn
      allowEmpty={false}
      sort={{ field: 'repairType', order: 'ASC' }}
    >
      <SelectInput optionText="repairType" optionValue="id" />
    </ReferenceInput>
    <DateInput
      label="Repair On or After"
      source="repairDate||$gte"
      alwaysOn
      data-cy="RepairReceivablesSheetFilterDatePaidBegin"
    />
    <DateInput
      label="Repair On or Before"
      source="repairDate||$lte"
      alwaysOn
      data-cy="RepairReceivablesSheetFilterDatePaidEnd"
    />
    <ReferenceInput
      label="Builder"
      source="builderId"
      reference="builder"
      resettable
      alwaysOn
      data-cy="RepairReceivablesSheetFilterBuilder"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        allowEmpty={false}
        optionText="name"
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Subdivision"
      source="subdivisionId"
      reference="subdivision"
      data-cy="RepairReceivablesSheetFilterSubdivision"
      resettable
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <TextInput
      label="Lot"
      source="jobLot||$eq"
      alwaysOn
      resettable
      data-cy="RepairReceivablesSheetFilterLot"
    />
    <SelectInput
      source="emailUploaded||$eq"
      label="Email Uploaded?"
      alwaysOn
      data-cy="RepairReceivablesSheetFilterEmailUploaded"
      choices={[
        { val: true, text: 'Yes' },
        { val: false, text: 'No' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
    />
    <TextInput
      source="internalInvoice||$eq"
      label="Internal Invoice #"
      resettable
      data-cy="RepairReceivablesSheetFilterInternalInvoice"
    />
    <SelectInput
      source="status||$eq"
      label="Status"
      alwaysOn
      data-cy="RepairReceivablesSheetFilterIsPaid"
      choices={[
        { val: 'Paid', text: 'Paid' },
        { val: 'Unpaid', text: 'Unpaid' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
    />
    <SelectInput
      source="poReleased||$eq"
      alwaysOn
      label="PO Released"
      data-cy="RepairReceivablesSheetFilterPOReleased"
      choices={[
        { val: true, text: 'Yes' },
        { val: false, text: 'No' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
    />
    <SelectInput
      source="readyToBook"
      label="Ready to Book"
      data-cy="RepairReceivablesSheetFilterIsReadyToBook"
      choices={[
        { val: true, text: 'Yes' },
        { val: false, text: 'No' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
      alwaysOn
    />
  </Filter>
);
